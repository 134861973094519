import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios
import Logo from "../assets/Logo.webp";
import MenubarIcon from "../assets/Icon (1).webp";
import placeholderImage1 from "../assets/gr-hero.png";
import placeholderImage2 from "../assets/gr-op.png";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import {
  FaCaretDown,
  FaUserCircle,
  FaWrench,
  FaHeartbeat,
  FaSignOutAlt,
} from "react-icons/fa";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [drawerDropdownOpen, setDrawerDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const drawerDropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown2 = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isUserLogin = sessionStorage.getItem("authToken") ? true : false;
  const userName = sessionStorage.getItem("userName");

  const handleLogoClick = () => {
    navigate("/");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDrawerDropdown = () => {
    setDrawerDropdownOpen(!drawerDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (
      drawerDropdownRef.current &&
      !drawerDropdownRef.current.contains(event.target)
    ) {
      setDrawerDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    const token = sessionStorage.getItem("authToken");

    if (!token) return;

    const options = {
      method: "DELETE",
      url: "https://apiv1.gadgetsreborn.com:7002/auth-service/auth/logout",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios.request(options);
      sessionStorage.clear();
      // Remove the token from sessionStorage
      navigate("/user/login"); // Redirect to the login page
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleMenuItemClick = (path) => {
    setDropdownOpen(false);
    setDrawerDropdownOpen(false);

    if (path === "/logout") {
      handleLogout();
    } else {
      navigate(path);
    }
  };

  return (
    <div className="pt-5">
      <div className="flex shadow items-center justify-between bg-white border-b border-transparent rounded-lg lg:rounded-full sm:mx-[30px] mx-[20px] px-4 py-[20px] lg:px-8">
        <div className="flex items-center gap-4 lg:gap-10">
          <img
            src={Logo}
            alt="Logo"
            className="h-8 cursor-pointer"
            onClick={handleLogoClick}
          />
          <nav className="hidden lg:flex gap-4 lg:gap-12">
            <Link
              to="/repair"
              className={`text-[#475467] font-plus-jakarta-sans text-sm lg:text-base leading-6 ${
                location.pathname === "/repair" ? "font-bold" : ""
              }`}
            >
              Repair
            </Link>
            <Link
              to="/care"
              className={`text-[#475467] font-plus-jakarta-sans text-sm lg:text-base leading-6 ${
                location.pathname === "/care" ? "font-bold" : ""
              }`}
            >
              Care
            </Link>
            <a
              href="https://www.gadgetsreborn.store/pages/about-us"
              className="text-[#475467] font-plus-jakarta-sans text-sm lg:text-base leading-6"
            >
              About Us
            </a>
            <a
              href="https://www.gadgetsreborn.store/pages/contact"
              className="text-[#475467] font-plus-jakarta-sans text-sm lg:text-base leading-6"
            >
              Contact Us
            </a>
            <div
              className="relative"
              onMouseEnter={() => setIsDropdownOpen(true)}
            >
              <button className="flex items-center gap-[6px] whitespace-nowrap text-[#475467] font-plus-jakarta-sans text-sm lg:text-base leading-6">
                Join Us
                {isDropdownOpen ? (
                  <FaChevronUp className="text-sm" />
                ) : (
                  <FaChevronDown className="text-sm" />
                )}
              </button>

              {/* Dropdown menu */}
              {isDropdownOpen && (
                <div className="absolute top-full mt-[34px] w-[600px] bg-white shadow-lg rounded-md z-50 ml-[-150px]">
                  <div
                    className="flex gap-4 p-4"
                    onMouseLeave={() => setIsDropdownOpen(false)}
                  >
                    {/* Card 1 */}
                    <div
                      className="border border-gray-300 rounded-[8px] overflow-hidden shadow-sm cursor-pointer"
                      onClick={() => navigate("/gadget-heros")}
                    >
                      <img
                        src={placeholderImage1}
                        alt="Gadgets Hero"
                        className="w-full h-auto object-cover p-2"
                      />
                      <div className="p-4">
                        <h2 className="text-lg font-bold text-[#101828] underline">
                          Gadgets Hero
                        </h2>
                        <p className="text-sm text-[#475467]">
                          Join our elite squad, master the latest repair
                          techniques, and become a legend in the world of gadget
                          repair while earning more and working smarter.
                        </p>
                      </div>
                    </div>
                    {/* Card 2 */}
                    <div
                      className="border border-gray-300 rounded-[8px] overflow-hidden shadow-sm cursor-pointer"
                      onClick={() => navigate("/gr-outpost")}
                    >
                      <img
                        src={placeholderImage2}
                        alt="GR Outpost"
                        className="w-full h-auto object-cover p-2"
                      />
                      <div className="p-4">
                        <h2 className="text-lg font-bold text-[#101828] underline">
                          GR Outpost
                        </h2>
                        <p className="text-sm text-[#475467]">
                          Elevate your tech repair business by joining the elite
                          GR Outpost network. Get vetted, access premium tools,
                          and grow your brand with the support of Gadgets
                          Reborn.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Link
              className={`text-[#475467] font-plus-jakarta-sans text-sm lg:text-base leading-6
                ${location.pathname === "/blogs" ? "font-bold" : ""}`}
              to="/blogs"
            >
              Blog
            </Link>
          </nav>
        </div>
        <div className="flex items-center">
          {!isUserLogin && location.pathname !== "/user/login" && (
            <div
              className="hidden lg:flex bg-[#fbc903] border font-semibold border-zinc-400 rounded-lg px-[16px] py-[8px] cursor-pointer transition-transform transform hover:scale-105 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
              onClick={() => navigate("/user/login")}
            >
              Login
            </div>
          )}
          <div className="sm:block hidden" ref={dropdownRef}>
            <div
              className="relative flex gap-3 items-center cursor-pointer"
              onClick={toggleDropdown}
            >
              {isUserLogin && (
                <div>
                  <div className="flex gap-1"></div>
                  <div className="flex items-center">
                    <p className="text-xs mr-1">{userName || "User"}</p>
                    <FaCaretDown />
                  </div>
                </div>
              )}
              {dropdownOpen && isUserLogin && (
                <div className="absolute right-0 py-2 flex flex-col items-start justify-center mt-[240px] w-56 bg-white border border-gray-300 rounded-lg shadow-lg z-20">
                  <div
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200 w-full rounded-t-lg"
                    onClick={() => handleMenuItemClick("/my-profile")}
                  >
                    <FaUserCircle className="text-gray-500 mr-4" />
                    <span className="text-gray-700">My Profile</span>
                  </div>
                  <div
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200 w-full"
                    onClick={() => handleMenuItemClick("/repair-history")}
                  >
                    <FaWrench className="text-gray-500 mr-4" />
                    <span className="text-gray-700">Repair History</span>
                  </div>
                  <div
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200 w-full"
                    onClick={() => handleMenuItemClick("/care-history")}
                  >
                    <FaHeartbeat className="text-gray-500 mr-4" />
                    <span className="text-gray-700">Care History</span>
                  </div>
                  <div
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer w-full rounded-b-lg"
                    onClick={() => handleMenuItemClick("/logout")}
                  >
                    <FaSignOutAlt className="text-gray-500 mr-4" />
                    <span className="text-gray-700">Logout</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="lg:hidden flex items-center">
            <button
              className="text-[#475467] focus:outline-none"
              onClick={toggleDrawer}
            >
              <img src={MenubarIcon} alt="Menu Icon" className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      {/* Drawer */}
      <div
        className={`fixed top-0 left-0 h-full w-[200px] max-h-[939px] lg:max-h-[965px] transition-transform duration-300 transform ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        } bg-white shadow-lg z-50`}
      >
        <button
          className="absolute top-4 right-4 text-[#475467]"
          onClick={toggleDrawer}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="mt-16 px-4">
          {/* User Profile Section */}
          <div
            className="relative flex gap-3 items-center cursor-pointer"
            ref={drawerDropdownRef}
            onClick={toggleDrawerDropdown}
          >
            {!isUserLogin && location.pathname !== "/user/login" && (
              <div
                className="font-semibold"
                onClick={() => navigate("/user/login")}
              >
                Login
              </div>
            )}
            {isUserLogin && (
              <div>
                <div className="flex gap-1"></div>
                <div className="flex items-center">
                  <p className="text-xs mr-1">{userName || "User"}</p>
                  <FaCaretDown />
                </div>
              </div>
            )}
            {drawerDropdownOpen && isUserLogin && (
              <div className="absolute right-0 py-2 flex flex-col items-start justify-center mt-[240px] sm:w-56 w-[175px] h-[200px] sm:h-auto bg-white border border-gray-300 rounded-lg shadow-lg z-20">
                <div
                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200 w-full rounded-t-lg"
                  onClick={() => handleMenuItemClick("/my-profile")}
                >
                  <FaUserCircle className="text-gray-500 mr-4" />
                  <span className="text-gray-700">My Profile</span>
                </div>
                <div
                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200 w-full"
                  onClick={() => handleMenuItemClick("/repair-history")}
                >
                  <FaWrench className="text-gray-500 mr-4" />
                  <span className="text-gray-700 whitespace-nowrap">
                    Repair History
                  </span>
                </div>
                <div
                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200 w-full"
                  onClick={() => handleMenuItemClick("/care-history")}
                >
                  <FaHeartbeat className="text-gray-500 mr-4" />
                  <span className="text-gray-700">Care History</span>
                </div>
                <div
                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer w-full rounded-b-lg"
                  onClick={() => handleMenuItemClick("/logout")}
                >
                  <FaSignOutAlt className="text-gray-500 mr-4" />
                  <span className="text-gray-700">Logout</span>
                </div>
              </div>
            )}
          </div>
          {/* Navigation Links */}
          <div className="mt-4">
            <Link
              to="/repair"
              className={`block text-[#475467] font-plus-jakarta-sans text-sm leading-6 mb-4 ${
                location.pathname === "/repair" ? "font-bold" : ""
              }`}
              onClick={toggleDrawer}
            >
              Repair
            </Link>
            <Link
              to="/care"
              className={`block text-[#475467] font-plus-jakarta-sans text-sm leading-6 mb-4 ${
                location.pathname === "/care" ? "font-bold" : ""
              }`}
              onClick={toggleDrawer}
            >
              Care
            </Link>
            <a
              href="https://www.gadgetsreborn.store/pages/about-us"
              className="block text-[#475467] font-plus-jakarta-sans text-sm leading-6 mb-4"
              onClick={toggleDrawer}
            >
              About Us
            </a>
            <a
              href="https://www.gadgetsreborn.store/pages/contact"
              className="block text-[#475467] font-plus-jakarta-sans text-sm leading-6"
              onClick={toggleDrawer}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
      {/* Overlay */}
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={toggleDrawer}
        ></div>
      )}
    </div>
  );
};

export default Navbar;
