import {
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
  FaFacebook,
  FaTiktok,
} from "react-icons/fa6";
import logo from "../assets/Logo2.webp";
import React from "react";

function Footer() {
  return (
    <div className="bg-[#fbc903] rounded-tl-gr rounded-tr-gr mt-12 font-grFont py-[46px]">
      <div className="flex flex-col lg:flex-row gap-[50px] lg:gap-[15%] xl:gap-[38%] justify-center mt-[40px] mx-5 ">
        <div className="flex flex-row lg:flex-col lg:gap-[150px]">
          <div className="flex flex-col sm:flex-row gap-4 items-center justify-start">
            <img
              src={logo}
              className=" w-full max-w-[146px] h-[34px] "
              alt="Logo"
            ></img>
            <div className="" id="tree-nation-offset-website"></div>
          </div>

          <div className="lg:flex lg:flex-col  flex flex-col w-full lg:gap-[20px]">
            <div className="flex flex-row-reverse xl:gap-[30px] xl:max-w-fit gap-4">
              <a
                href="https://www.linkedin.com/company/gadgetsreborn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="w-[24px] h-[24px]" />
              </a>
              <a
                href="https://www.instagram.com/gadgetsreborn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="w-[24px] h-[24px]" />
              </a>
              <a
                href="https://x.com/_gadgetsreborn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter className="w-[24px] h-[24px]" />
              </a>
              <a
                href="https://www.facebook.com/gadgetsreborn.info"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="w-[24px] h-[24px]" />
              </a>
              <a
                href="https://www.tiktok.com/@gadgets.reborn?_t=8okWttM7moa&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok className="w-[24px] h-[24px]" />
              </a>
            </div>
            <div className="hidden w-full lg:flex font-[400] font-plus-jakarta-sans leading-[18px] text-[12px]">
              <p>Copyright © {new Date().getFullYear()} Gadgets Reborn LLC.</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:gap-[90px] gap-[20px]">
          <div className="flex flex-col lg:flex-row self-center gap-[70px] lg:gap-[40px] w-full font-[400] leading-[24px] text-[16px]">
            <div className="flex-row flex gap-[30px]">
              <div className="flex flex-col w-1/2 gap-[12px] lg:mr-4">
                <p className="font-[700] font-DMSans">Company</p>
                <a
                  href="https://www.gadgetsreborn.store/pages/about-us"
                  target="_blank"
                  className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                  rel="noreferrer"
                >
                  About Us
                </a>
                <a
                  href="https://www.gadgetsreborn.store/pages/contact"
                  target="_blank"
                  className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </div>
              <div className="flex flex-col w-1/2 gap-[12px] lg:mr-4">
                <p className="font-[700] font-DMSans whitespace-nowrap">
                  Join our Community
                </p>
                <a
                  href="https://x.com/_gadgetsreborn"
                  target="_blank"
                  className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                  rel="noreferrer"
                >
                  X
                </a>
                <a
                  href="https://www.instagram.com/gadgetsreborn/"
                  target="_blank"
                  className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  href="https://www.facebook.com/gadgetsreborn.info"
                  target="_blank"
                  className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                  rel="noreferrer"
                >
                  Facebook
                </a>
                <a
                  href="https://www.tiktok.com/@gadgets.reborn?_t=8okWttM7moa&_r=1"
                  target="_blank"
                  className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                  rel="noreferrer"
                >
                  Tiktok
                </a>
                <a
                  href="https://www.linkedin.com/company/gadgetsreborn/"
                  target="_blank"
                  className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </div>
            </div>
            <div className="flex flex-col w-full gap-[12px]">
              <p className="font-[700] font-DMSans">Support</p>
              <a
                href="https://www.gadgetsreborn.store/pages/help-centre"
                target="_blank"
                className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                rel="noreferrer"
              >
                Help Center
              </a>
              <a
                href="https://zfrmz.com/LKzppogcURAGYveQMGL6"
                target="_blank"
                className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4"
                rel="noreferrer"
              >
                For Partners
              </a>
              {/* <a className="whitespace-nowrap font-plus-jakarta-sans cursor-pointer w-1/4">Suggest a Feature</a> */}
            </div>
          </div>
          <div className="flex gap-[30px] lg:gap-[30px] leading-[18px] font-[400] text-[12px] mt-10  lg:mt-[-2px]">
            <a
              href="https://www.gadgetsreborn.store/pages/contact"
              target="_blank"
              className="cursor-pointer text-[#101828] font-plus-jakarta-sans whitespace-nowrap"
              rel="noreferrer"
            >
              Complaints
            </a>
            <a
              href="https://www.gadgetsreborn.store/pages/terms-conditions"
              target="_blank"
              className="cursor-pointer text-[#101828] font-plus-jakarta-sans whitespace-nowrap"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            <a
              href="https://www.gadgetsreborn.store/pages/privacy-policy"
              target="_blank"
              className="cursor-pointer text-[#101828] font-plus-jakarta-sans whitespace-nowrap"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="flex lg:hidden font-[400] leading-[18px] text-[12px] mt-[-16px]">
          <p>Copyright © {new Date().getFullYear()} Gadgets Reborn LLC.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
