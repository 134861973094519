import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BlogImg from "../assets/blog1.png";
import refurbushed from "../assets/refurbished.png";

const blogs = [
  {
    id: 1,
    title: "Best Gadgets Buying Guide for Mobiles and Laptops",
    img: BlogImg,
    path: "/blogs/1",
  },
  {
    id: 2,
    title: "How to Find the 10 Best Refurbished Gadgets",
    img: refurbushed,
    path: "/blogs/2",
  },
];

function Blog() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col min-h-screen sm:mx-[50px]">
        <Navbar />
        <div className="flex-grow">
          <h1 className="text-4xl text-center mt-10 text-[#101828] font-semibold">
            Blogs
          </h1>
          <div className="flex items-center justify-center gap-8 p-10">
            {blogs.map((blog) => (
              <div
                key={blog.id}
                className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 w-[400px] cursor-pointer"
              >
                <img
                  src={blog.img}
                  alt={blog.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h2 className="text-2xl font-semibold mb-2 text-[#101828]">
                    {blog.title}
                  </h2>
                  <p className="text-gray-700 mb-4">
                    Explore the latest trends in tech gadgets and innovations...
                  </p>
                  <button
                    onClick={() => navigate(blog.path)}
                    className="text-[#101828] lg:flex bg-[#fbc903] border font-semibold border-zinc-400 rounded-lg px-[16px] py-[8px] cursor-pointer transition-transform transform hover:scale-105 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Blog;
